import React from "react";
import axios from "axios";
import { checkJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import Sidebar from '../../Layouts/Sidebar';

const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Setting extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            data: [],
            banks: [],
            fullname: '',
            businessname: '',
            email: '',
            currentpassword: '',
            newpassword: '',
            phone: '',
            accountno : '',
            website : '',
            DataisLoaded: false,
            textValue: '',
            webhook_url : '',
        };
        
    }
    
    componentDidMount = async() => {     
        const token = checkJwt();  
        const response = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/details`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token
				}
			}
		)
        console.log(response.data);
        this.setState({
            data: response.data,
            DataisLoaded: true,
        });
        const banks = await axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/bank-list`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        )
        console.log(banks.data);
        this.setState({
            banks: banks.data,
            DataisLoaded: true,
        });
    }
    handleData = (e) => {
        e.preventDefault();
        console.log(this.state.data);
    }
    handleChange  = (e) => {
		e.preventDefault();
        console.log(e.target.value);
		this.setState({ [e.target.name]: e.target.value });
	};
   
    handleInformationUpdate = async(e) => {
        e.preventDefault();
        const token = checkJwt();
        const response = await axios.put(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/details`,
            {
                fullname: this.state.fullname,
                businessname: this.state.businessname,
                phone: this.state.phone,
                accountno: this.state.accountno,
                website : this.state.website,
                bankid: this.state.bankid,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        )
        console.log(response.data);
        alert("Information Updated Successfully");
        this.setState({
            data: response.data,
            DataisLoaded: true,
        });
    }
    handlePasswordUpdate = async(e) => {
        e.preventDefault();
        const token = checkJwt();
        const response = await axios.post(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/password/change`,
            {
                currentPassword: this.state.currentpassword,
                newPassword: this.state.newpassword,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        )
        console.log(response);
        if(response.data.status === 200){
            alert("Password Changed Successfully");
            // distroy token and logout
            this.logout();

        }else{
            alert("Password change failed");
        }
        this.setState({
            currentpassword: '',
            newpassword: '',
        });
    }
    logout() {
        localStorage.clear();
        window.location.href = '/';
    }
    handleWebhookUpdate = async(e) => {
        e.preventDefault();
        const token = checkJwt();
        const response = await axios.post(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/webhook`,
            {
                webhook_url: this.state.webhook_url,
                use_webhook: '1'
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        )
        console.log(response);
        alert("Webhook Updated Successfully");
        this.setState({
            webhook_url: response.data.webhook_url
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="fixed-left">
                    <div className="topbar">

                        <div className="topbar-left">
                            <a href="index.html" className="logo"><span>NET<span>PLUS</span></span><i className="mdi mdi-layers"></i></a>
                        </div>

                        <Navbar/>
                    </div>
                    
                        <Sidebar/>

                    <div className="content-page">
                        <div className="content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="page-title-box">
                                            {/* render data */}
                                            <h4 className="page-title">Settings </h4>
                                            <ol className="breadcrumb p-0 m-0">
                                                <li className="active">
                                                    Settings
                                                </li>
                                            </ol>
                                            <div className="clearfix"></div>
                                            {/* render Setting data */}
                                            
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card-box">
                                                        {/* <h4 className="header-title m-t-0 m-b-30">Default Tabs</h4> */}

                                                        <ul className="nav nav-tabs navtab-bg nav-justified">
                                                            <li className="active">
                                                                <a href="#general" data-toggle="tab" aria-expanded="true">
                                                                    <span className="visible-xs"><i className="fa fa-home"></i></span>
                                                                    <span className="hidden-xs">Personal Information</span>
                                                                </a>
                                                            </li>
                                                            <li className="">
                                                                <a href="#developer" data-toggle="tab" aria-expanded="false">
                                                                    <span className="visible-xs"><i className="fa fa-user"></i></span>
                                                                    <span className="hidden-xs">Developer</span>
                                                                </a>
                                                            </li>
                                                            <li className="">
                                                                <a href="#webhook" data-toggle="tab" aria-expanded="false">
                                                                    <span className="visible-xs"><i className="fa fa-envelope-o"></i></span>
                                                                    <span className="hidden-xs">Webhook</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">
                                                            <div className="tab-pane active" id="general">
                                                                <div className="row">
                                                                    <form onSubmit={this.handleInformationUpdate}>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">Business Name</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                onChange={this.handleChange}
                                                                                name="businessname"
                                                                                defaultValue={this.state.data.businessname} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">Full Name</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                name="fullname"
                                                                                onChange={this.handleChange}
                                                                                defaultValue={this.state.data.fullname} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">Email</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                readOnly 
                                                                                defaultValue={this.state.data.email} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <p className="text-muted">Bank</p>
                                                                        <div className="form-group">
                                                                            <select 
                                                                                onChange={this.handleChange} 
                                                                                name="bankid"
                                                                                defaultValue={this.state.data.bankid}
                                                                                className="form-control">
                                                                                {this.state.banks.map((bank) => (
                                                                                    <option value={bank.id}>{bank.bank_name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <p className="text-muted">Account Number</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                onChange={this.handleChange}
                                                                                name="accountno" 
                                                                                defaultValue={this.state.data.accountno} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">Website</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control" 
                                                                                onChange={this.handleChange}
                                                                                name="website" 
                                                                                defaultValue={this.state.data.website} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">Phone</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="text" 
                                                                                className="form-control"
                                                                                name="phone"
                                                                                onChange={this.handleChange}
                                                                                defaultValue={this.state.data.phone} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center mt-4">
                                                                        <div className="form-group">
                                                                            <button type="submit" className="btn btn-primary" >Save Changes</button>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    </form>
                                                                    </div>
                                                                    <hr/>
                                                                {/* <div className="row">
                                                                    <form onSubmit={this.handlePasswordUpdate}>
                                                                    <div className="col-md-6">
                                                                        <p className="text-muted">Current Passsword</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="password" 
                                                                                className="form-control" 
                                                                                name="currentpassword"
                                                                                onChange={this.handleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p className="text-muted">New Password</p>
                                                                        <div className="form-group">
                                                                            <input 
                                                                                type="password" 
                                                                                onChange={this.handleChange}
                                                                                name="newpassword"
                                                                                className="form-control" 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-center mt-4">
                                                                        <div className="form-group">
                                                                            <button type="submit" className="btn btn-primary">Update Password</button>
                                                                        </div>
                                                                    </div>
                                                                    </form>
                                                                </div> */}
                                                            </div>
                                                            <div className="tab-pane" id="developer">
                                                                {this.state.data.confirmed === 1 ? ( 
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">API Key Test Key</p>
                                                                        <div className="form-group">
                                                                            <input type="text" 
                                                                                className="form-control" 
                                                                                value={this.state.data.test_key}
                                                                                onClick={() =>  {navigator.clipboard.writeText(this.state.data.test_key); alert('Test key copied')}} 
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">API Secret Key</p>
                                                                        <div className="form-group">
                                                                            <input type="text" 
                                                                                className="form-control" 
                                                                                value={this.state.data.secret_key}
                                                                                onClick={() =>  {navigator.clipboard.writeText(this.state.data.secret_key); alert('Secret key copied')}} 
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-muted">Merchant ID</p>
                                                                        <div className="form-group">
                                                                            <input type="text" 
                                                                                className="form-control" 
                                                                                value={this.state.data.merchantid}
                                                                                onClick={() =>  {navigator.clipboard.writeText(this.state.data.merchantid); alert('Merchant ID copied')}} 
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                ) : (
                                                                    <div className="row">
                                                                    <div className="col-md-6">
                                                                        <p className="text-muted">API Key Test Key</p>
                                                                        <div className="form-group">
                                                                            <input type="text" 
                                                                                className="form-control" 
                                                                                value={this.state.data.test_key}
                                                                                onClick={() =>  {navigator.clipboard.writeText(this.state.data.test_key); alert('Test key copied')}} 
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p className="text-muted">Test MerchantID</p>
                                                                        <div className="form-group">
                                                                            <input type="text" 
                                                                                className="form-control" 
                                                                                value={this.state.data.test_merchantid}
                                                                                onClick={() =>  {navigator.clipboard.writeText(this.state.data.test_merchantid); alert('Merchant ID copied')}} 
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                            <div className="tab-pane" id="webhook">
                                                                <p className="display-3">Your Webhook Url</p>
                                                                <form onSubmit={this.handleWebhookUpdate} >
                                                                <div className="form-group">
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        placeholder="https://api-webhook-url.com" 
                                                                        name="webhook_url"
                                                                        onChange={this.handleChange}
                                                                        defaultValue={this.state.data.webhook_url} />
                                                                    <br/>
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn btn-primary">Save Changes</button>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-12 text-center mt-4">
                                                                    
                                                                </div> */}
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 

                        <footer className="footer text-left">
                                © NetPlus Inc.
                        </footer>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Setting;
