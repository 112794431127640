import React from "react";
import axios from "axios";
import { checkJwt, checkAdminJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Sidebar from '../../Layouts/Sidebar';


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Transaction extends React.Component {    
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
            excelReport: [],
            startDate: "",
            endDate: "",
            transaction_status: "",
            fileExtension : ".xlsx",
            fileType : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        };
    }
    
    componentDidMount = async() => {       
        const token = checkJwt();
        const response = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transactions`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token
				}
			}
		)
        // const dataSet = response.data.transactions.map((item) => (
        //     [item.trans_id, item.order_id, item.full_name, item.amount, item.transaction_status, item.narration, item.created_at]
        // ));

        this.setState({
            items: response.data.transactions,
            DataisLoaded: true,
        });
    }

    exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: this.state.fileType });
        FileSaver.saveAs(data, fileName + this.state.fileExtension);
    };

    handleExcelReport = (e, startDate, endDate, transaction_status) => {
        // preventDefault();
        e.preventDefault();

        const token = checkJwt();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response, countResponseVale: response.data.transactions.length});
            if(response.data.transactions.length > 0){
                this.setState({
                    excelReport: response.data.transactions
                });
                this.exportToCSV(response.data.transactions, "transaction_report_from_" + startDate + "_to_" + endDate);
            }else{
                alert("No record found");
            }
            this.setState({
                excelReport: response.data.transactions
            });
        });
    }
    handlePDFReport = (e, startDate, endDate, transaction_status) => {
        // preventDefault();
        e.preventDefault();
        const token = checkJwt();
        if(startDate == null || startDate === "") startDate = "2020-01-01";
        if(endDate == null || endDate === "") endDate = new Date().toISOString().slice(0, 10);
        if(transaction_status == null || transaction_status === "")transaction_status = "all";
        
        axios.get(
            `${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/transaction-report`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    status: transaction_status
                }
            }
        ).then((response) => {
            // console.log({response});
        });
    }



    render() {
        return (
            <React.Fragment>
                <div className="fixed-left">
                    <div className="topbar">

                        <div className="topbar-left">
                            <a href="index.html" className="logo"><span>NET<span>PLUS</span></span><i className="mdi mdi-layers"></i></a>
                        </div>

                        <Navbar/>
                    </div>
                    
                        <Sidebar/>

                    <div className="content-page">
                        <div className="content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="page-title-box">
                                            {/* render data */}
                                            <h4 className="page-title">Transactions </h4>
                                            <ol className="breadcrumb p-0 m-0">
                                                <li className="active">
                                                    Transactions
                                                </li>
                                            </ol>
                                            <div className="clearfix"></div>
                                            {/* render transaction data */}
                                            
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card-box table-responsive">
                                                    <div className="col-sm-3 mb-5">
                                                            <label>Start Date</label>
                                                            <input 
                                                                type="date" 
                                                                className="form-control input-sm"
                                                                onChange={(event) => this.setState({startDate: event.target.value})}
                                                             />
                                                        {/* </div> */}
                                                    </div>
                                                    {/* <br/> */}
                                                    <div className="col-sm-3 mb-5">
                                                            <label>End Date</label>
                                                            <input 
                                                                type="date" 
                                                                className="form-control input-sm" 
                                                                // value={this.state.dateTo}
                                                                onChange={(event) => this.setState({endDate: event.target.value})}

                                                            />
                                                        {/* </div> */}
                                                    </div>
                                                    {/* <br/> */}
                                                    <div className="col-sm-3 mb-5">
                                                        {/* <div className="" id="datatable_length"> */}
                                                            <label>Status</label>
                                                            <select className="form-control input-sm" aria-controls="datatable" onChange={(event) => this.setState({transaction_status: event.target.value})}>
                                                            
                                                                <option value="All">All</option>
                                                                <option value="Pending">Pending</option>
                                                                <option value="Success">Success</option>
                                                                <option value="Failed">Failed</option>
                                                            </select>
                                                        {/* </div> */}
                                                    </div>
                                                    {/* <br/> */}
                                                    {/* <div className="col-sm-3 ">
                                                        <br/>
                                                        <input type="button" value="Download PDF" className="btn btn-primary btn-md" />
                                                        <br/>
                                                    </div> */}
                                                     {this.state.items.length > 0 ? (
                                                    <div className="col-sm-3 ">
                                                        <br/>
                                                        <input 
                                                            type="button" 
                                                            value="Download Excel" 
                                                            className="btn btn-success btn-md"
                                                            onClick={(event) => this.handleExcelReport(event, this.state.startDate, this.state.endDate, this.state.transaction_status)}
                                                         />
                                                    </div>
                                                    ) : (
                                                        <div className="col-sm-3 ">
                                                        <br/>
                                                        <input 
                                                            type="button" 
                                                            value="No data to download" 
                                                            className="btn btn-danger btn-md"
                                                            disabled
                                                         />
                                                    </div>
                                                    )}
                                                  <br/><br/><br/><br/>
                                                       
                                                        <table id="datatable" className="table table-striped table-bordered mt-4">
                                                            <thead>
                                                                <tr>
                                                                    <th>Transaction ID</th>
                                                                    <th>Order ID</th>
                                                                    <th>Customer</th>
                                                                    <th>Amount</th>
                                                                    <th>Status</th>
                                                                    {/* <th>Description</th> */}
                                                                    <th>Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.items.length > 0 ? (
                                                                this.state.items.map((item) => (
                                                                    <tr>
                                                                        <td>{item.trans_id}</td>
                                                                        <td>{item.order_id}</td>
                                                                        <td>{item.full_name}</td>
                                                                        <td>{item.amount}</td>
                                                                        <td>{item.transaction_status}</td>
                                                                        {/* <td>{item.narration}</td> */}
                                                                        {/* render only date */}
                                                                        <td>{item.created_at.split(',')[0]}</td>
                                                                        {/* <td>{item.created_at}</td> */}
                                                                    </tr>
                                                                ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="6">No transaction yet</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 

                        </div> 

                        <footer className="footer text-left">
                                © NetPlus Inc.
                        </footer>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Transaction;
