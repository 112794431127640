import React from "react";
// import "bootstrap/dist/css/bootstrap.css";

import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	HashRouter,
	Router,
} from "react-router-dom";

import SignInForm from "./Components/Auth/SignInForm";
import SignUpForm from "./Components/Auth/SignUpForm";
import Transaction from "../src/Components/Transaction/Transaction";
import Setting from "../src/Components/Setting/Settings";
import Dashboard from "../src/Components/Dashboard/Dashboard";


const isLoggedIn = localStorage.getItem("token");


const Routing = () => {
	return (
		<Switch>
			<Route exact path="/">
				<SignInForm />
			</Route>
			<Route exact path="/sign-up">
				<SignUpForm />
			</Route>

			<Route path="/dashboard">
				<Dashboard />
			</Route>
			<Route path="/transactions">
				<Transaction />
			</Route>
			<Route path="/settlements">
				<Transaction />
			</Route>
			<Route path="/customers">
				<Transaction />
			</Route>
			<Route path="/wallets">
				<Transaction />
			</Route>
			<Route path="/settings">
				<Setting />
			</Route>
		</Switch>
	);
};




function App() {
	return (
		<HashRouter>
			<Routing />
		</HashRouter>
	);
}

export default App;
