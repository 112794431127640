import React from "react";
import axios from "axios";
import { checkJwt } from "../../Utilities/processJWT";
import Navbar from "../../Layouts/Navbar";
import { Switch } from "react-router-dom";
import Sidebar from '../../Layouts/Sidebar';
// import routes from "../Routes/routes";

const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
class Dashboard extends React.Component {
	constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false,
			thisWeekCount : [],
			thisMonthCount : [],
			thisYearCount : [],
			yearTillDateCount: [],
            period: {
				week: "This Week",
				month: "This Month",
				year: "This Year",
				custom: "Custom"
			},
        };
    }
    
    componentDidMount = async() => {       
		const token = checkJwt(); 
		const getThisWeek = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/stats`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token
				},
				params: {"period": this.state.period.week}
			}
		);
		const getThisMonth = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/stats`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token
				},
				params: {"period": this.state.period.month}
			}
		);
		const getThisYear = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/stats`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token
				},
				params: {"period": this.state.period.year}
			}
		);
		const getCustom = await axios.get(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/stats`, 
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token
				},
				params: {
					"period": this.state.period.custom,
					"startDate": "2020-01-01",
					// set end_date current date 
					"endDate": new Date().toISOString().slice(0, 10)
				}
			}
		);
		// console.log({responseNew: getThisMonth.data});
        this.setState({
            items: [],
			thisWeekCount: getThisWeek.data,
			thisMonthCount: getThisMonth.data,
			thisYearCount: getThisYear.data,
			yearTillDateCount: getCustom.data,
            DataisLoaded: true,
        });

    }

	render() {
		return (
			<React.Fragment>
				<div className="fixed-left">
					<div className="topbar">

						<div className="topbar-left">
							<a href="index.html" className="logo"><span>NET<span>PLUS</span></span><i className="mdi mdi-layers"></i></a>
						</div>

						<Navbar/>
					</div>
					
						<Sidebar/>

					<div className="content-page">
						<div className="content">
							<div className="container">
								<div className="row">
									<div className="col-xs-12">
										<div className="page-title-box">
											{/* render data */}
											<h4 className="page-title">Dashboards </h4>
											<ol className="breadcrumb p-0 m-0">
												<li className="active">
													Dashboards
												</li>
											</ol>
											<div className="clearfix"></div>
											{/* bootstrap cards */}

											
										</div>
										<div className="row">
											<h4 className="m-l-5">Transaction Till Date</h4>
											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-primary">
													<i className="mdi mdi-chart-areaspline widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Total Transactions</p>
														<h2><span data-plugin="counterup">{ this.state.yearTillDateCount.totalTransactions }</span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b>0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-success">
													<i className="mdi mdi-layers widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User This Month">Total Successful</p>
														<h2><span data-plugin="counterup">{ this.state.yearTillDateCount.successful } </span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b>0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-warning">
													<i className="mdi mdi-access-point-network widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Total Pending</p>
														<h2><span data-plugin="counterup">{ this.state.yearTillDateCount.pending }</span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-danger">
													<i className="mdi mdi-account-convert widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User Today">Total Failed</p>
														<h2><span data-plugin="counterup">{ this.state.yearTillDateCount.failed } </span> <small><i className="mdi mdi-arrow-down text-danger"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

										</div>
										<div className="row">
											<h4 className="m-l-5">Transaction This Week</h4>
											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-primary">
													<i className="mdi mdi-chart-areaspline widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Total Transactions</p>
														<h2><span data-plugin="counterup">{ this.state.thisWeekCount.totalTransactions }</span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b>0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-success">
													<i className="mdi mdi-layers widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User This Month">Total Successful</p>
														<h2><span data-plugin="counterup">{ this.state.thisWeekCount.successful } </span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b>0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-warning">
													<i className="mdi mdi-access-point-network widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Total Pending</p>
														<h2><span data-plugin="counterup">{ this.state.thisWeekCount.pending }</span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-danger">
													<i className="mdi mdi-account-convert widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User Today">Total Failed</p>
														<h2><span data-plugin="counterup">{ this.state.thisWeekCount.failed } </span> <small><i className="mdi mdi-arrow-down text-danger"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

										</div>
										<div className="row">
											<h4 className="m-l-5">Transaction This Month</h4>
											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-primary">
													<i className="mdi mdi-chart-areaspline widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Total Transactions</p>
														<h2><span data-plugin="counterup">{ this.state.thisMonthCount.totalTransactions }</span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-success">
													<i className="mdi mdi-layers widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User This Month">Total Successful</p>
														<h2><span data-plugin="counterup">{ this.state.thisMonthCount.successful } </span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b>0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-warning">
													<i className="mdi mdi-access-point-network widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="Statistics">Total Pending</p>
														<h2><span data-plugin="counterup">{ this.state.thisMonthCount.pending }</span> <small><i className="mdi mdi-arrow-up text-success"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6">
												<div className="card-box widget-box-two widget-two-danger">
													<i className="mdi mdi-account-convert widget-two-icon"></i>
													<div className="wigdet-two-content">
														<p className="m-0 text-uppercase font-600 font-secondary text-overflow" title="User Today">Total Failed</p>
														<h2><span data-plugin="counterup">{ this.state.thisMonthCount.failed } </span> <small><i className="mdi mdi-arrow-down text-danger"></i></small></h2>
														<p className="text-muted m-0"><b>Last:</b> 0</p>
													</div>
												</div>
											</div>

										</div>
									</div>
									
								</div>
							</div> 

						</div> 

						<footer className="footer text-left">
							 © NetPlus Inc.
						</footer>

					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Dashboard;
