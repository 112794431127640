import axios from "axios";
import { checkJwt, checkAdminJwt } from "../Utilities/processJWT";


const REACT_APP_BASE_NETPLUS_MERCHANT_URL = process.env.REACT_APP_BASE_NETPLUS_MERCHANT_URL;
const REACT_APP_BASE_URL_NETPOS = process.env.REACT_APP_BASE_URL_NETPOS;
const REACT_APP_BASE_URL_QR = process.env.REACT_APP_BASE_URL_QR;
const REACT_APP_BASE_URL_PBT = process.env.REACT_APP_BASE_URL_PBT;
const REACT_APP_BASE_URL_NIBSS = process.env.REACT_APP_BASE_URL_NIBSS;
const REACT_APP_BASE_URL_CONTACTLESS = process.env.REACT_APP_BASE_URL_CONTACTLESS;


export function userLogin(params) {
	// console.log({params, url: REACT_APP_BASE_NETPLUS_MERCHANT_URL});
	return axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/login`,
			{ email: params.email, password: params.password },
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then(function (response) {
			return response;
		})
		.catch(function (error) {
			return error.response;
		});
}

// async function for user registration
export const userRegister = async(params) => {
	const response = await axios
		.post(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/register`,
			{
				email: params.email, 
				password: params.password, 
				businessname: params.businessname, 
				fullname: params.fullname
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}

export const updateMerchant = async(params) => {
	const response = await axios
		.put(
			`${REACT_APP_BASE_NETPLUS_MERCHANT_URL}/details`,
			{
				// new_password
				password: params.password, 
				businessname: params.businessname, 
				fullname: params.fullname
			}, 
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		console.log({response});
	return response;
}


