import React from 'react';

class Sidebar extends React.Component {
    render() {
        return (
            <React.Fragment>
				<div className="left side-menu">
        <div className="sidebar-inner slimscrollleft">

            <div id="sidebar-menu">
                <ul>
                    <li className="has_sub">
                        <a href="/#/dashboard" className="waves-effect"><i className="mdi mdi-view-dashboard"></i><span> Dashboard </span> </a>
                    </li>
					<li className="has_sub">
                        <a href="/#/transactions" className="waves-effect"><i className="mdi mdi-cash-multiple"></i><span> Transactions </span> </a>
                    </li>
					<li className="has_sub">
                        <a href="/#/settlements" className="waves-effect"><i className="glyphicon glyphicon-briefcase"></i><span> Settlements </span> </a>
                    </li>
					{/* <li className="has_sub">
                        <a href="/#/" className="waves-effect"><i className="mdi mdi-view-dashboard"></i><span> Users </span> </a>
                    </li> */}
					<li className="has_sub">
                        <a href="/#/customers" className="waves-effect"><i className="mdi mdi-account-star"></i><span> Customers </span> </a>
                    </li>
					<li className="has_sub">
                        <a href="/#/wallets" className="waves-effect"><i className="mdi mdi-wallet"></i><span> Wallet </span> </a>
                    </li>
					<li className="has_sub">
                        <a href="/#/settings" className="waves-effect"><i className="mdi mdi-settings"></i><span> Settings </span> </a>
                    </li>

                </ul>
            </div>
        </div>
       

        </div>
            </React.Fragment>
        )
    }
}

export default Sidebar;