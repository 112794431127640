import React from 'react';

class Navbar extends React.Component {

	logout() {
        localStorage.clear();
        window.location.href = '/';
    }
    render() {
        return (
            <React.Fragment>
				<div className="navbar navbar-default" role="navigation">
				<div className="container">
					<ul className="nav navbar-nav navbar-right">
						<li className="dropdown user-box">
							<a
							href="#"
							className="dropdown-toggle waves-effect user-link"
							data-toggle="dropdown"
							aria-expanded="true"
							>
							<img src="./assets/images/users/avatar-1.png" alt="user-img" className="img-circle user-img"/>
							</a>

							<ul className="dropdown-menu dropdown-menu-right arrow-dropdown-menu arrow-menu-right user-list notify-list">
							<li>
								<a href="#"  onClick={(event) => this.logout()}>
								<i className="ti-power-off m-r-5"></i> Logout
								</a>
							</li>
							</ul>
						</li>
					</ul>
				</div>
				</div>
            </React.Fragment>
        )
    }
}

export default Navbar;