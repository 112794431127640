import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
// import { Button, Input, FormGroup, Col, CustomInput } from "reactstrap";
import { userRegister } from "../../Utilities/api";
import { decodeJwt, verifyJwt , parseJwt} from "../../Utilities/processJWT";

export const SignUp =  () => {
	const [showmsg, setShowMsg, setRedirect, setDecoded] = useState("");
	
	return (
		<Formik
			initialValues={{
				email: "",
				password: "",
				businessname: "",
				fullname: "",
			}}
			onSubmit={(values, { setSubmitting }, props) => {
				setTimeout(async() => {
					const sendRequest = await userRegister({
						email: values.email,
						password: values.password,
						businessname: values.businessname,
						fullname: values.fullname,
					});
					console.log(sendRequest);
					if (sendRequest && sendRequest.status === 400) {
						alert("Email or password incorrect");
						setSubmitting(false);
					} else if (sendRequest.status === 200) {
						setTimeout(() => {
							console.log("found");
							alert("Registration Successful");
							window.location.replace("#/");
						}, 2000);
					}
			}, 1000);
		}}>
			{(props) => {
				const {
					values,
					touched,
					errors,
					isSubmitting,
					handleChange,
					handleBlur,
					handleSubmit,
					nextStep,
					isChecked,
					handleRememberMe,
				} = props;
				return (
					<section>
						<div className="container-alt">
							<div className="row">
								<div className="col-sm-12">

									<div className="wrapper-page">

										<div className="m-t-40 account-pages">
											<div className="text-center account-logo-box">
												<h2 className="text-uppercase">
													<Link to="#" className="text-success">
														<span><img src="../assets/images/netplus.png" alt="" height="36"></img></span>
													</Link>
												</h2>
											</div>
											<div className="account-content">
												<form onSubmit={handleSubmit} className="form-horizontal">
													<div className="form-group ">
														<div className="col-xs-12">
															<input 
																className="form-control" type="text" name="businessname" 
																required="" placeholder="Business Name *"
																value={values.businessname}
																onChange={handleChange}
															/>
														</div>
													</div>
													<div className="form-group ">
														<div className="col-xs-12">
															<input 
																className="form-control" type="text" name="fullname" 
																required="" placeholder="Full Name *"
																value={values.fullname}
																onChange={handleChange}
															/>
														</div>
													</div>

													<div className="form-group ">
														<div className="col-xs-12">
															<input 
																className="form-control" type="email" name="email" 
																required="" placeholder="Email"
																value={values.email}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="col-xs-12">
															<input 
																className="form-control" type="password" required 
																placeholder="Password" name="password"
																value={values.password}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
														</div>
													</div>

													<div className="form-group account-btn text-center m-t-10">
														<div className="col-xs-12">
															<button 
																className="btn w-md btn-bordered btn-success waves-effect waves-light" 
																type="submit" disabled={isSubmitting} >
																	{isSubmitting ? "Loading…" : "Sign Up"}
															</button>
														</div>
													</div>

											</form>

												<div className="clearfix"></div>

											</div>
										</div>

										<div className="row m-t-50">
											<div className="col-sm-12 text-center">
												<p className="text-muted">Already have account?<Link to="/" className="text-primary m-l-5"><b>Sign In</b></Link></p>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</section>
				);
			}}
			</Formik>
		);
	};

	class SignUpForm extends React.Component {
		constructor(props) {
			super(props);
	
			this.state = {
				showmsg: "",
				redirect: null,
				isChecked: "",
			};
		}
	
		handleRedirect = () => {
			return <Redirect to="/dashboard" />;
		};
	
		handleRememberMe = () => {
			console.log("isChecked", this.state.isChecked);
		};
	
		render() {
			return (
				<React.Fragment>
					<SignUp
						showmsg={this.state.showmsg}
						handleRedirect={this.handleRedirect}
						isChecked={this.state.isChecked}
						handleRememberMe={this.handleRememberMe}
					/>
				</React.Fragment>
			);
		}
	}


export default SignUpForm;
