import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { userLogin } from "../../Utilities/api";
import { verifyJwt} from "../../Utilities/processJWT";

export const SignIn = function () {
	const [showmsg, setShowMsg, setRedirect, setDecoded] = useState("");
	const user = sessionStorage.getItem("user");

	return (
		<Formik
			initialValues={{
				email: user,
				password: "",
				isLoggedIn: false,
				rememberMe: false,
			}}
			onSubmit={(values, { setSubmitting }, props) => {
				setTimeout(() => {
					userLogin({
						email: values.email,
						password: values.password,
					}).then((response) => {
							console.log(response);
							if (response && response.status === 400) {
								setShowMsg("Email or password incorrect");
								setSubmitting(false);
							} else if (response.status === 200 && response.data.token) {
								console.log("resp",response);
								if (!verifyJwt(response.data.token)) {
									const decoded = verifyJwt(response.data.token);
									console.log("respeeee", decoded, response.data.token);
									setSubmitting(false);
									setShowMsg("Server Error");
								} else if (verifyJwt(response.data.token)) {
									console.log("happen");
									if (values.rememberMe) {
										sessionStorage.setItem("user", values.email);
										// sessionStorage.setItem("password", values.password);
									}
									localStorage.setItem("isLoggedIn", true);
									localStorage.setItem("firstSignup", false);
									localStorage.setItem("token", response.data.token);

									localStorage.setItem("redirect", false);
									setTimeout(() => {
										console.log("found");
										window.location.replace("#/dashboard");
									}, 2000);
								
								} else {
								setShowMsg("Opps! Server error");
								setSubmitting(false);
								console.log(values.rememberMe);
								// window.location.replace('#/dashboard');
								 }
					}
				})
						.catch(function (error) {
							setShowMsg("Opps! Server error");
							setSubmitting(false);

							// window.location.replace('#/dashboard');
						});
				}, 1000);
			}}
			validationSchema={Yup.object().shape({
				email: Yup.string().email("Invalid email address").required("Required"),
				password: Yup.string().required("No password provided."),
			})}>
			{(props) => {
				const toggle = (event) => {
					event.preventDefault();
					let showPassword = document.querySelector("#toggle-password");
					let password = document.querySelector("#password");
					if (event.target.className === "fa fa-fw fa-eye field-icon") {
						showPassword.className = "fa fa-fw fa-eye-slash field-icon";
						password.type = "text";
					} else {
						showPassword.className = "fa fa-fw fa-eye field-icon";
						password.type = "password";
					}
				};

				const {
					values,
					isSubmitting,
					handleChange,
					handleBlur,
					handleSubmit,
					nextStep,
					isChecked,
					handleRememberMe,
				} = props;

				return (
					<section>
						<div className="container-alt">
							<div className="row">
								<div className="col-sm-12">
									<div className="wrapper-page">
										<div className="m-t-40 account-pages">
											<div className="text-center account-logo-box">
												<h2 className="text-uppercase">
													<a href="#" className="text-success">
														<span><img src="../assets/images/netplus.png" alt="" height="36"></img></span>
													</a>
												</h2>
											</div>
											<div className="account-content">
											<form onSubmit={handleSubmit} className="form-horizontal">
													<div className="form-group ">
														<div className="col-xs-12">
															<input 
																className="form-control" type="email" name="email" 
																required="" placeholder="Email"
																value={values.email}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="col-xs-12">
															<input 
																className="form-control" type="password" required 
																placeholder="Password" name="password"
																value={values.password}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
														</div>
													</div>

													<div className="form-group ">
														<div className="col-xs-12">
															<div className="checkbox checkbox-success">
																<input 
																	id="checkbox-signup" 
																	type="checkbox" checked
																	name="rememberMe"
																	onChange={handleChange}
																/>
																<label for="checkbox-signup">
																	Remember me
																</label>
															</div>

														</div>
													</div>

													{/* <div className="form-group text-center m-t-30">
														<div className="col-sm-12">
															<a href="page-recoverpw.html" className="text-muted"><i className="fa fa-lock m-r-5"></i> Forgot your password?</a>
														</div>
													</div> */}

													<div className="form-group account-btn text-center m-t-10">
														<div className="col-xs-12">
															<button 
																className="btn w-md btn-bordered btn-success waves-effect waves-light" 
																type="submit" disabled={isSubmitting} >
																	{isSubmitting ? "Loading…" : "Login"}
															</button>
														</div>
													</div>

											</form>

												<div className="clearfix"></div>

											</div>
										</div>


										<div className="row m-t-50">
											<div className="col-sm-12 text-center">
												{/* link to signUp form */}
												<p className="text-muted">Don't have an account? <Link to="/sign-up" className="text-dark m-l-5"><b>Sign Up</b></Link></p>
												{/* <p className="text-muted">Don't have an account? <a href="page-register.html" className="text-primary m-l-5"><b>Sign Up</b></a></p> */}
											</div>
										</div>

									</div>

								</div>
							</div>
						</div>
        			</section>
				);
			}}
		</Formik>
	);
};

class SignInForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showmsg: "",
			redirect: null,
			isChecked: "",
		};
	}

	handleRedirect = () => {
		return <Redirect to="/dashboard" />;
	};

	handleRememberMe = () => {
		console.log("isChecked", this.state.isChecked);
	};

	render() {
		return (
			<React.Fragment>
				<SignIn
					showmsg={this.state.showmsg}
					handleRedirect={this.handleRedirect}
					isChecked={this.state.isChecked}
					handleRememberMe={this.handleRememberMe}
				/>
			</React.Fragment>
		);
	}
}

export default SignInForm;
